// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { Drawer, ConsoleOutput, Button } from '@plesk/ui-library';
import { Observer, getComponent, Task, escapeHtml, Locale } from 'jsw';
import PropTypes from 'prop-types';
import useEventListener from 'common/hooks/useEventListener';
import useNavigate from 'hooks/useNavigate';
import { addToast } from 'helpers/toasts';

const STATUS_RUNNING = 'running';
const getTaskProgressBar = () => getComponent('asyncProgressBarWrapper');
const ButtonsTranslate = Locale.getTranslate('components.buttons');

// Workaround for lists that do not support reload
const sameUrls = (redirectUrl, pageUrl) => {
    if (redirectUrl === pageUrl) {
        return true;
    }

    try {
        const params = new URLSearchParams(new URL(redirectUrl, window.location.origin).search);
        return params.get('returnUrl') === pageUrl || params.get('pageUrl') === pageUrl;
    } catch {}

    return true;
};

// Workaround for three different sets of task statuses
const getStatus = status => {
    switch (status) {
        case 'STATUS_NOT_STARTED': return Task.STATUS_NOT_STARTED;
        case 'STATUS_STARTED':
        case 'STATUS_RUNNING':
        case Task.STATUS_STARTED: return STATUS_RUNNING;
        case 'STATUS_DONE': return Task.STATUS_DONE;
        case 'STATUS_CANCELED': return Task.STATUS_CANCELED;
        case 'STATUS_ERROR': return Task.STATUS_ERROR;
        default: return status;
    }
};

const DrawerWithProgress = ({
    children = null,
    dataType,
    isOpen = false,
    onClose,
    task = null,
    pageUrl = null,
    onTaskComplete = undefined,
    onError = undefined,
    autoCloseTimeout = undefined,
    ...props
}) => {
    const navigate = useNavigate();
    const [steps, setSteps] = useState([]);
    const [title, setTitle] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [status, setStatus] = useState(null);
    const taskId = task ? task.id : null;

    const handleTaskUpdate = ({ id, steps, status: taskStatus, errors, output, progressValue, progressTitle, progressTitleHtml }) => {
        if (!isOpen || taskId !== id) {
            return;
        }

        const title = progressTitleHtml || escapeHtml(progressTitle);
        const status = getStatus(taskStatus);

        const content = (errors = [], output = []) => {
            const onErrorExecute = typeof onError === 'function' && errors.length > 0;
            return (
                <>
                    {onErrorExecute && onError()}
                    {errors.map(error => (<div key={error}>{error}</div>))}
                    {output.length > 0 && (<ConsoleOutput data-testid="console-output">{output}</ConsoleOutput>)}
                </>
            );
        };

        setStatus(status);
        if (steps && steps.length) {
            setTitle(title);
            setSteps(steps.map(({ errors, status, output, ...props }) => ({
                children: content(errors, output),
                status: getStatus(status),
                ...props,
            })));
        } else {
            setSteps([{
                title,
                status,
                progress: Number(progressValue),
                children: content(errors, output),
            }]);
        }
    };

    const handleTaskComplete = ({ id, redirect: redirectUrl, status }) => {
        if (!isOpen || taskId !== id) {
            return;
        }

        if (typeof onTaskComplete === 'function') {
            onTaskComplete({ status });
        }
        if (Task.STATUS_DONE === status) {
            setRedirectUrl(redirectUrl?.url || redirectUrl);
        }
    };

    useEffect(() => {
        const taskProgressBar = getTaskProgressBar();
        const onOpen = () => {
            taskProgressBar?.hide();
            setSteps([]);
            setTitle(null);
            setRedirectUrl(null);
            setStatus(null);
        };

        const onClose = async () => {
            if (!isInProgress()) {
                await deleteTask();
            }
            taskProgressBar?.show();
        };

        isOpen ? onOpen() : onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (taskId) {
            getTaskProgressBar()?.update();
            handleTaskUpdate(task);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId, task]);

    useEventListener('plesk:taskStepUpdate', handleTaskUpdate, Observer);
    useEventListener('plesk:taskComplete', handleTaskComplete, Observer);

    const isInProgress = () => Task.STATUS_NOT_STARTED === status || STATUS_RUNNING === status;

    const handleClose = () => {
        if (!redirectUrl) {
            onClose();
            return;
        }

        if (sameUrls(redirectUrl, pageUrl)) {
            navigate(redirectUrl, { replace: true });
            onClose();
            return;
        }

        navigate(redirectUrl);
    };

    const deleteTask = () => taskId && getTaskProgressBar()?.getItems().find(item => item.getId() === taskId)
        ?.remove();

    const onFinish = () => {
        deleteTask();
        addToast({
            intent: 'success',
            // eslint-disable-next-line react/no-danger
            message: <span dangerouslySetInnerHTML={{ __html: title || steps[0].title }} />,
        });
    };

    return (
        <Drawer
            data-type={dataType}
            isOpen={isOpen}
            onClose={handleClose}
            progress={steps.length ? {
                cancelable: false,
                // eslint-disable-next-line react/no-danger
                title: <span dangerouslySetInnerHTML={{ __html: title }} />,
                steps,
                onFinish,
                autoCloseTimeout,
                buttons: autoCloseTimeout === null && Task.STATUS_DONE === status ? (
                    <Button
                        intent="primary"
                        onClick={handleClose}
                    >
                        <ButtonsTranslate content="finish" />
                    </Button>
                ) : null,
            } : null}
            hideButton={isInProgress()}
            {...props}
        >
            {children}
        </Drawer>
    );
};

DrawerWithProgress.propTypes = {
    children: PropTypes.node,
    dataType: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    task: PropTypes.shape({
        id: PropTypes.string.isRequired,
        steps: PropTypes.array,
        status: PropTypes.string,
        progressTitle: PropTypes.string,
        progressTitleHtml: PropTypes.string,
    }),
    pageUrl: PropTypes.string,
    onTaskComplete: PropTypes.func,
    onError: PropTypes.func,
    autoCloseTimeout: PropTypes.number,
};

export default DrawerWithProgress;

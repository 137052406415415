// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useCallback, useState } from 'react';

import { readLocalStorageValue } from 'common/helpers/readLocalStorageValue';

export const useLocalStorage = function (key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => readLocalStorageValue(key, initialValue));

    const setValue = useCallback(value => {
        setStoredValue(value);

        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key]);

    return [storedValue, setValue];
};

export default useLocalStorage;
